import './Footer.scss';

import { ReactComponent as LogoFooter } from '../../img/logo/logo_pic.svg';

export const Footer = () => {
  return (
    <footer id="contacts" className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__section">
            <h2 className="section__subtitle section__subtitle--white section__subtitle--footer">Контакти</h2>
            <div className="footer__group">
              <a href="tel:+380966427197" className="link link--white">+380966427197</a>
              <a href="mailto:info@fainotekh.com" className="link link--white">info@fainotekh.com</a>
              <a
                href="https://drive.google.com/drive/folders/1IaxmIMsELfJ2-_oZjspgbKNLiPl7Rc8n"
                target="_blank"
                rel="noreferrer"
                className="link link--white"
              >
                Документи компанії
              </a>
            </div>
          </div>
          <div className="footer__logo">
            <LogoFooter width={'100%'} height={'100%'} />
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <span className="footer__copy">© Файнотех Сервіс 2024</span>
      </div>
    </footer>
  );
};
