type Section = {
  id: number;
  link: string;
  name: string;
}

export const sectionsData: Section[] = [
  {
    id: 2,
    link: 'services',
    name: 'Послуги'
  },
  {
    id: 3,
    link: 'objects',
    name: 'Наші об’єкти'
  },
  {
    id: 4,
    link: 'app',
    name: 'Застосунок'
  },
  {
    id: 5,
    link: 'interaction',
    name: 'Взаємодія із забудовником'
  },
  {
    id: 6,
    link: 'contacts',
    name: 'Контакти'
  },
] 