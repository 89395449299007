import React from 'react';
import { sectionsData } from '../../data/sections';
import { Link } from 'react-scroll';
import './Navigation.scss';

type Props = {
  mainClasses: string;
  linkClasses: string;
  activLinkClasses?: string;
  onMenu?: () => void;
}

export const Navigation: React.FC<Props> = (
  { mainClasses, linkClasses, activLinkClasses = '', onMenu = () => { } }
) => {
  const items = sectionsData;

  const headerHeight = mainClasses.includes('nav--desktop')
    ? 75
    : 55;

  return (
    <nav className={mainClasses}>
      <ul className="nav__list">
        {items.map((item) => {
          return (
            <li className="nav__item" key={item.id}>
              <Link
                spy={true}
                delay={0}
                to={item.link}
                className={linkClasses}
                activeClass={activLinkClasses}
                smooth={true}
                duration={200}
                offset={-headerHeight}
                onClick={onMenu}
              >
                {item.name}
              </Link>
            </li>
          )
        })}
        <li className="nav__item">
          <a
            href="https://drive.google.com/drive/folders/1IaxmIMsELfJ2-_oZjspgbKNLiPl7Rc8n?usp=sharing"
            className={linkClasses}
            target="_blank"
            rel="noreferrer"
          >
            Документи компанії
          </a>
        </li>
      </ul>
    </nav>
  );
};


