import cn from 'classnames';
import { useEffect, useState } from 'react';
import { BREAKPOINTS } from '../../setting/setting';
import { Link } from 'react-scroll';
import './Header.scss';

import { ReactComponent as Logo } from '../../img/logo/logo_2_black.svg';
import { ReactComponent as LogoWhite } from '../../img/logo/logo_2_white.svg';
import { ReactComponent as Menu } from '../../img/icons/menu.svg';
import { Navigation } from '../Navigation/Navigation';


export const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeMenu = () => {
    if (isMenuActive) {
      setIsMenuActive(false);
    }
  }

  const headerHeight = screenWidth >= BREAKPOINTS.DESKTOP
    ? 75
    : 55;

  const logo = screenWidth > BREAKPOINTS.DESKTOP
    ? <LogoWhite width={'100%'} height={'100%'} />
    : <Logo width={'100%'} height={'100%'} />;

  return (
    <>
      <header className="page__header header">
        <div className="container">
          <div className="header__content">
            <Link
              spy={true}
              delay={0}
              to={'home'}
              className={'header__logo logo'}
              smooth={true}
              duration={200}
              onClick={closeMenu}
              offset={-headerHeight}
            >
               {logo}
            </Link>
            <button
              className="header__menu"
              type="button"
              onClick={() => {
                setIsMenuActive(!isMenuActive);
              }}
            >
              <Menu width={'100%'} height={'100%'} />
            </button>
            <Navigation
              mainClasses='header__nav nav nav--desktop'
              linkClasses='nav__link link link--white'
            />
          </div>
        </div>
      </header>
      <div className={cn('header__navbar', { 'header__navbar--active': isMenuActive })}>
        <div className="container">
          <Navigation
            mainClasses='header__nav nav'
            linkClasses='nav__link'
            onMenu={closeMenu}
          />
        </div>
      </div>
    </>
  );
};
