import React, { useEffect, useState } from 'react';
import './App.scss';

import { Header } from './components/Header/Header';
import { Duties } from './Sections/Duties/Duties';
import { Application } from './Sections/Application/Application';
import { BREAKPOINTS } from './setting/setting';
import { Interaction } from './Sections/Interaction/Interaction';
import { Footer } from './components/Footer/Footer';
import { Home } from './Sections/Home/Home';

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const headerHeight = screenWidth < BREAKPOINTS.DESKTOP
    ? 55
    : 75;

  const introHeight = screenHeight - headerHeight;

  const blockHeight = screenWidth >= BREAKPOINTS.DESKTOP
    ? screenHeight - 300
    : 'unset';

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResizeH = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResizeH);

    return () => {
      window.removeEventListener('resize', handleResizeH);
    };
  }, []);

  return (
    <div className="App">
      <div className="page">
        <Header />
        <main className="page__main main">
          <Home introHeight={introHeight} />
          <Duties screenWidth={screenWidth} />
          <section
            id="objects"
            className="page__section section section--no-padding-top"
          >
            <iframe
              title='Розташування наших об’єктів'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2543.719671986621!2d30.373503400000008!3d50.3904241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ca2c3aa40001%3A0x80811cfc4766a9be!2sWellspring!5e0!3m2!1suk!2sua!4v1715110542677!5m2!1suk!2sua"
              width="100%"
              height={`${screenHeight}px`}
              loading="lazy"
              style={{ border: 0 }}
            >
            </iframe>
          </section>
          <Application />
          <Interaction blockHeight={blockHeight} />
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;
