import React from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import './Interaction.scss';

type Props = {
  blockHeight: number | 'unset';
}

export const Interaction: React.FC<Props> = ({ blockHeight }) => {
  const { ref: intTitle, inView: inViewIntTitle } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
  });
  const { ref: intBlock1, inView: inViewBlock1 } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
  });
  const { ref: intBlock2, inView: inViewBlock2 } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
  });

  return (
    <section className="page__section section section--silver section--no-padding-top">
      <div className="section__element hidden--desktop">
        <img src="./images/5.jpg" alt="" className="section__img" />
      </div>
      <div className="container">
        <div id='interaction' className="section__content section__content--padding-top">
          <h1
            ref={intTitle}
            className={cn('section__title scroll__animationX',
              { 'scroll__animationX--active': inViewIntTitle })}
          >
            Як ми взаємодіємо з забудовником?
          </h1>
          <div className="blocks-container" style={{ height: blockHeight }}>
            <div className="blocks">
              <div
                className={cn('block scroll__animationY', { 'scroll__animationY--active': inViewBlock1 })}
                ref={intBlock1}
              >
                <h3 className="block__title">Долучаємося у проєктування</h3>
                <p className="block__text">та озеленення ЖК, аби поліпшити його експлуатацію та обслуговування.</p>
              </div>
              <div
                className={cn('block scroll__animationY', { 'scroll__animationY--active': inViewBlock2 })}
                ref={intBlock2}
              >
                <h3 className="block__title">Допомогаємо з організацією</h3>
                <p className="block__text">заселення та видачі ключів на оглядах квартир.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};