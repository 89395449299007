import './Duties.scss';
import cn from 'classnames';

import { useInView } from 'react-intersection-observer';
import { Parallax } from 'react-parallax';

import { ReactComponent as Care } from '../../img/duty/care.svg';
import { ReactComponent as Clean } from '../../img/duty/clean.svg';
import { ReactComponent as Green } from '../../img/duty/green.svg';
import { ReactComponent as Handshake } from '../../img/duty/handshake.svg';
import { ReactComponent as Repair } from '../../img/duty/repair.svg';
import { BREAKPOINTS } from '../../setting/setting';
import React from 'react';

type Props = {
  screenWidth: number;
}

export const Duties: React.FC<Props> = ({ screenWidth }) => {
  const isDesktop = screenWidth >= BREAKPOINTS.DESKTOP;

  const { ref: duty1, inView: inView1 } = useInView({
    threshold: 0.1,
  });
  const { ref: duty2, inView: inView2 } = useInView({
    threshold: 0.1,
    delay: isDesktop ? 200 : 0,
  });
  const { ref: duty3, inView: inView3 } = useInView({
    threshold: 0.1,
    delay: isDesktop ? 400 : 0,
  });
  const { ref: duty4, inView: inView4 } = useInView({
    threshold: 0.1,
    delay: isDesktop ? 600 : 0,
  });
  const { ref: duty5, inView: inView5 } = useInView({
    threshold: 0.1,
    delay: isDesktop ? 800 : 0,
  });
  const { ref: dutyTitle, inView: inViewTitle } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
  });

  return (
    <section
      className="page__section section section--no-padding-top"
    >
      <div className="section__element">
        {screenWidth < BREAKPOINTS.DESKTOP
          ? (<img src="./images/2.jpg" alt="" className="section__img" />)
          : (<Parallax bgImage="./images/2.jpg" strength={300} className="section__img--paralax" />)}
      </div>
      <div className="container">
        <div className="section__content section__content--padding-top" id="services">
          <h1
            className={cn('section__title scroll__animationX', { 'scroll__animationX--active': inViewTitle })}
            ref={dutyTitle}
          >
            Чим займається компанія-управитель?
          </h1>
          <div className="section__element">
            <div className="duties">
              <div className="duties__item" ref={duty1}>
                <div
                  className={cn('duty scroll__animationY', { 'scroll__animationY--active': inView1 })}
                >
                  <div className="duty__logo">
                    <Care width={'100%'} height={'100%'} />
                  </div>
                  <h3 className="duty__title">Доглядає</h3>
                  <p className="duty__description">За прибудинковою територією, спортивними та дитячими майданчиками</p>
                </div>
              </div>
              <div className="duties__item" ref={duty2}>
                <div
                  className={cn('duty scroll__animationY', { 'scroll__animationY--active': inView2 })}
                >
                  <div className="duty__logo">
                    <Clean width={'100%'} height={'100%'} />
                  </div>
                  <h3 className="duty__title">Прибирає</h3>
                  <p className="duty__description">Місця загального користування будинку</p>
                </div>
              </div>
              <div className="duties__item" ref={duty3}>
                <div
                  className={cn('duty scroll__animationY', { 'scroll__animationY--active': inView3 })}
                >
                  <div className="duty__logo">
                    <Repair width={'100%'} height={'100%'} />
                  </div>
                  <h3 className="duty__title">Обслуговує</h3>
                  <p className="duty__description">Та підтримує належний стан внутрішніх інженерних систем будинку</p>
                </div>
              </div>
              <div className="duties__item" ref={duty4}>
                <div
                  className={cn('duty scroll__animationY', { 'scroll__animationY--active': inView4 })}
                >
                  <div className="duty__logo">
                    <Green width={'100%'} height={'100%'} />
                  </div>
                  <h3 className="duty__title">Піклується</h3>
                  <p className="duty__description">Про озеленення</p>
                </div>
              </div>
              <div className="duties__item" ref={duty5}>
                <div
                  className={cn('duty scroll__animationY', { 'scroll__animationY--active': inView5 })}
                >
                  <div className="duty__logo">
                    <Handshake width={'100%'} height={'100%'} />
                  </div>
                  <h3 className="duty__title">Відстоює інтереси</h3>
                  <p className="duty__description">Мешканців перед забудовником та містом</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
