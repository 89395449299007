import './Application.scss';
import cn from 'classnames';

import { ReactComponent as Done } from '../../img/icons/done.svg';
import { useInView } from 'react-intersection-observer';

export const Application = () => {
  const { ref: appTitle, inView: inViewAppTitle } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
  });
  const { ref: appDescription, inView: inViewAppDescription } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 0px 300px",
    delay: 100,
  });
  const { ref: appImage1, inView: inViewAppImage1 } = useInView({
    threshold: 0.1,
    rootMargin: "600px",
    delay: 200,
  });
  const { ref: appDah, inView: inViewAppDah } = useInView({
    threshold: 0.1,
    rootMargin: "0px 300px 0px 300px",
    delay: 200,
  });

  return (
    <section
      id="app"
      className="page__section section"
    >
      <div className="container">
        <div className="section__content">
          <h1 ref={appTitle} className={cn('section__title scroll__animationX', { 'scroll__animationX--active': inViewAppTitle })}>
            Застосунок ДАХ
          </h1>
          <h2
            ref={appDescription}
            className={cn('section__subtitle scroll__animationX', { 'scroll__animationX--active': inViewAppDescription })}
          >
            Інструмент для управління домівкою онлайн
          </h2>
          <div className="section__container section__container--flex">
            <div
              ref={appImage1}
              className={cn('section__element section__element--position--sub scroll__animationXdesc--image',
                { 'scroll__animationXdesc--image-active': inViewAppImage1 })}
            >
              <img src="./images/dim/1.png" alt="" className="section__img section__img--rotate section__img--heigth" />
            </div>
            <div className="section__element section__element--position--main">
              <div className="advantages" ref={appDah}>
                <h3 className={cn('advantages__title scroll__animationYdesc', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  Переваги застосунку ДАХ
                </h3>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-1', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Перегляд документообігу ОСББ</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-2', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Демонстрація актуального списку боржників</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-3', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Месенджер, де зазначено імʼя та квартиру кожного учасника</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-4', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Участь у загальних та установчих зборах online зі смартфону</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-5', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Контакти технічних служб, спеціалістів по обслуговуванню будинку завжди під рукою</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-6', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Історія сплат та нарахувань по власній квартирі</p>
                </div>
                <div className={cn('advantages__item scroll__animationYdesc scroll__transition-delay-7', { 'scroll__animationYdesc--active': inViewAppDah })}>
                  <div className="advantages__icon">
                    <Done width={'100%'} height={'100%'} />
                  </div>
                  <p className="advantages__text">Оплата квитанцій прямо в застосунку</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section__element">
            <img src="./images/dim/2.png" alt="" className="section__img section__img--scale" />
          </div>
        </div>
      </div>
    </section>
  );
};
