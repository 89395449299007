import React from "react";
import './Home.scss';

type Props = {
  introHeight: number;
}

export const Home: React.FC<Props> = ({ introHeight }) => {
  return (
    <section
      id="home"
      className="page__section section section--black"
      style={{ height: `${introHeight}px`, paddingTop: 0 }}
    >
      <div className="container">
        <div
          className="section__content section__content--intro"
          style={{ height: `${introHeight}px`, paddingTop: 35 }}
        >
          <div className="section__element section__element--intro">
            <div className="image-tabs">
              <div className="image-tabs__img image-tabs__img--1 animation__slide-down animation__slide-down--del1"></div>
              <div className="image-tabs__img image-tabs__img--2 animation__slide-down"></div>
              <div className="image-tabs__img image-tabs__img--3 animation__slide-down animation__slide-down--del1"></div>
              <div className="image-tabs__img image-tabs__img--4 animation__slide-down"></div>
              <div className="image-tabs__img image-tabs__img--5 animation__slide-down animation__slide-down--del1"></div>
            </div>
          </div>
          <h1
            className="
                  section__title
                  section__title--white
                  section__title--intro
                  animation__slide-left"
          >
            Комфорт та безпека вашого житла - наш пріоритет!
          </h1>
          <p
            className="
                section__description
                section__description--white
                section__description--intro
                animation__slide-left
                animation__slide-left--del1"
          >
            Компанія-управитель, що обслуговує житлові будинки та територію поруч
          </p>
        </div>
      </div>
    </section>
  );
};
